import React, { useState, useEffect } from "react";
import VideoPlayer from "./components/VideoPlayer";
import { useParams } from "react-router-dom";
import { Host, Port } from "./Routes/apiConfig";
import Axios from "axios";
import "./App.css";

const App = () => {

  const [videos, setVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const { gameId } = useParams(); // Get the value from the URL 
  const defaultGameId = gameId || '0000'; // Set default value if gameId is null 

  const fetchVideos = async () => {
    try {
      const response = await Axios.get(`${Host}:${Port}/fetch?filePath=${defaultGameId}`);
      const data = response.data;
      setVideos(data);
    } catch (error) {
      console.error("Error fetching videos:", error);
    }
  };

  useEffect(() => {
    fetchVideos();
  }, []);

  const handleVideoSelect = (video) => {
    setSelectedVideo(video);
  };

  return (
    <div className="app-container">
      <h1>Swin Goals</h1>
      <div className="selected-video-container">
        {/* <h2>Selected Video</h2> */}
        {selectedVideo && selectedVideo.name && selectedVideo.path && (
          <VideoPlayer
            title={selectedVideo.name}
            videoUrl={`${Host}:${Port}/${selectedVideo.path}`}
          />
        )}
      </div>
      <div className="videos-list-container">
        <h2>Goals List</h2>
        {videos && videos.data && Array.isArray(videos.data.data) ? (
          <ul className="videos-list">
            {videos.data.data.map((video) => (
              <li key={video.name} onClick={() => handleVideoSelect(video)}>
                {video.name}
              </li>
            ))}
          </ul>
        ) : (
          <h2>Error: No videos available</h2>
        )}
      </div>
    </div>
  );
};

export default App;
