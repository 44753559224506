// VideoPlayer.js
import React from 'react';
import ReactPlayer from 'react-player';
import './VideoPlayer.css'
const VideoPlayer = ({ videoUrl }) => {
  return (
    <div>
      <ReactPlayer
        url={videoUrl}
        controls
        playing  // Autoplay the video
        volume={0.8}  // Set the volume to 80%
        muted={false} // Mute the video
        loop={true}  // Don't loop the video
        width="100%"
        height="100%"
        progressInterval={1000}  // Update progress every second
      />
    </div>
  );
};

export default VideoPlayer;
